.app {
  text-align: center;
}

.navbar {
  background-color: #282c34;
  padding: 1rem;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar h1 {
  margin: 0;
  font-size: 1.5rem;
}

.map-container {
  flex: 1;
  width: 100%;
  height: 100%;
}